<template>
  <node
    title="结束"
    :is-root="true"
    :content="content"
    placeholder="添加抄送人"
    :header-bgc="headerBgc" header-icon="el-icon-user-solid"
    :isFooter="true"
    :show-error="showError" 
    style="pointer-events: none;"
  />
</template>
<script>
import Node from './Node.vue'
export default {
  name: "EndNode",
  components: {Node},
  props:{
    config:{
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed:{
    content(){
      // console.log(this.config.props);
      if (this.config?.props?.cc.assignedUser.length > 0){
        let texts = []
        this.showError = false
        this.config?.props?.cc.assignedUser.forEach(org => texts.push(org.dataName))
        return String(texts).replaceAll(',', '、')
      } else {
        return '结束流程'
      }
    },
    headerBgc() {
      if (this.$store.state.process.diagramMode === 'viewer') {
        return this.config.props.headerBgc
      } else {
        return 'linear-gradient(90deg, #A5B1CC 0%, #D1D9E8 100%)'
      }
    },
  },
  created(){
  },
  mounted(){
  },
  data() {
    return {
      showError: false
    }
  },
  methods: {
    // 校验数据配置的合法性
    validate(err){
      console.log('结束节点',this.config.props)
      if(this.config.props.ccType && this.config.props.cc.assignedUser.length === 0) {
        this.showError = true
        err.push('未设置抄送人')
      }else {
        this.showError = false
      }
    },
  }
}
</script>

<style scoped>

</style>
